import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, graphql } from 'gatsby';
import { useFlexSearch } from 'react-use-flexsearch';
import orderBy from 'lodash/orderBy';
import filter from 'lodash/filter';
import { Layout } from '../components/layout';
import { Seo } from '../components/seo';
import { Content } from '../components/content';
import { SearchBar } from '../components/search';
import {
    forMediumDevices,
    forExtraSmallDevices
} from '../utils/styles';

export const Post = styled(Link)`
    width: 100%;
    max-width: 100%;
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    min-height: 11rem;
    border-radius: 10px;
    overflow: hidden;
    transition: all .3s ease;
    box-shadow: 0 1px 1px 0 rgba(31, 35, 46, .15);
    color: inherit;
    text-decoration: inherit;
    background-color: ${({ theme }) => theme.colors.white};

    &:hover {
        transform: translate(0px, -2px);
        box-shadow: 0 15px 45px -10px rgba(10, 16, 34, .2);
    }

    ${forMediumDevices(`
        width: 48%;
        max-width: 100%;
        margin: 0 0.9% 1.5%;
        flex-direction: column;
    `)}

    ${forExtraSmallDevices(`
        width: 98%;
        max-width: 100%;
        margin: 2%;
        flex-direction: column;
    `)}
`;

export const Thumbnail = styled.div`
    width: 30%;
    max-width: 100%;
    min-height: 11rem;
    background-size: cover;
    background-position: 50% 50%;
    background-image: ${({ image }) => image};

    ${forMediumDevices(`
        width: 100%;
    `)}

    ${forExtraSmallDevices(`
        width: 100%;
    `)}
`;

export const PostContent = styled.div`
    padding: 1rem;
    width: 70%;

    ${forMediumDevices(`
        width: 100%;
    `)}

    ${forExtraSmallDevices(`
        width: 100%;
    `)}
`;

export const PostTitle = styled.h2`
    margin: 0 0 10px;
    font-size: 30px;
    font-weight: 400;

    ${forMediumDevices(` 
        margin: 0 0 5px;
    `)}

    ${forExtraSmallDevices(`
        margin: 0 0 5px;
    `)}
`;

export const PostExcerpt = styled.p`
    margin-top: 0;
`;

export const PostDate = styled.span`
    font-size: 12px;
`;

const Footer = styled.div`
    display: flex;
    width: 100%;
`;

const Pagination = styled.nav`
    display: flex;
    flex: 1;
    align-items: center;

    & > p {
        flex: 1;
        text-align: center;
    } 
`;

const PreviousPage = styled(Link)`
    font-size: 18px;
    display: inline-block;
    color: ${({ theme }) => theme.colors.bodyColor};
    transition: transform .2s;
    text-align: left;

    &:hover {
        transform: translateX(-5px);
    }
`;

const NextPage = styled(Link)`
    font-size: 18px;
    display: inline-block;
    color: ${({ theme }) => theme.colors.bodyColor};
    transition: transform .2s;

    &:hover {
        transform: translateX(5px);
    }
`;

const HighlightedText = styled.span`
    background-color: ${({ theme }) => theme.colors.darkBlue};
    color: ${({ theme }) => theme.colors.white};
`;

const useSearch = ({ index, store }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const results = useFlexSearch(
        searchQuery,
        index,
        store
    );

    const unFlattenResults = results => results.map(post => {
        const {
            slug,
            date,
            name,
            cover,
            summary
        } = post;
        return {
            node: {
                slug,
                date,
                name,
                cover,
                summary
            }
        };
    });

    const shouldShowSearchResults = !!searchQuery;
    const matcher = searchQuery ? new RegExp(`(${searchQuery})`, 'gi') : null;

    return {
        renderSearchBar: () => (
            <SearchBar
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
            />
        ),
        shouldShowSearchResults,
        getSearchResults: () => shouldShowSearchResults ? unFlattenResults(
            orderBy(
                filter(
                    results,
                    ({ name, summary }) => name.match(matcher) || summary.match(matcher)
                ),
                ({ sorter }) => new Date(sorter),
                ['desc']
            )
        ) : [],
        getHighlightedText: ({ text }) => {
            if (!shouldShowSearchResults) {
                return text;
            }

            const parts = text.split(matcher);
            const lowercaseSearchQuery = searchQuery.toLowerCase();
            return (
                <span>
                    {
                        parts.map(
                            part => part.toLowerCase() === lowercaseSearchQuery ?
                                <HighlightedText>{part}</HighlightedText> :
                                part
                        )
                    }
                </span>
            );
        }
    };
};

const PostsTemplate = ({
    data: {
        allGoogleDocs,
        localSearchPages
    },
    pageContext
}) => {
    const { currentPage, numPages } = pageContext;
    const isFirst = currentPage === 1;
    const isLast = currentPage === numPages;
    const prevPage = currentPage - 1 === 1 ? '/' : `/${(currentPage - 1).toString()}`;
    const nextPage = `/${(currentPage + 1).toString()}`;
    const {
        renderSearchBar,
        shouldShowSearchResults,
        getSearchResults,
        getHighlightedText
    } = useSearch(localSearchPages);
    const posts = shouldShowSearchResults ? getSearchResults() : allGoogleDocs.edges;

    return (
        <Layout>
            <Seo title='Home' />
            <Content>
                {renderSearchBar()}
                {posts.map(({ node }) => (
                    <Post to={node.slug} key={node.slug}>
                        {
                            node.cover?.image?.childImageSharp?.gatsbyImageData && (
                                <Thumbnail image={`url(${node.cover.image.childImageSharp.gatsbyImageData.images.fallback.src})`} />
                            )
                        }
                        <PostContent>
                            <PostTitle>{getHighlightedText({ text: node.name })}</PostTitle>
                            <PostExcerpt>{getHighlightedText({ text: node.summary })}</PostExcerpt>
                            <PostDate>{node.date}</PostDate>
                        </PostContent>
                    </Post>
                ))}
                {!shouldShowSearchResults && (
                    <Footer>
                        <Pagination role='navigation'>
                            <p>
                                {!isFirst && (

                                    <PreviousPage to={prevPage} rel='prev'>
                                        ← Previous Page
                                    </PreviousPage>

                                )}
                            </p>
                            <p>
                                <span>
                                    Page
                                    {' '}
                                    {currentPage}
                                    {' '}
                                    of
                                    {' '}
                                    {numPages}
                                </span>
                            </p>
                            <p>
                                {!isLast && (

                                    <NextPage to={nextPage} rel='next'>
                                        Next Page →
                                    </NextPage>

                                )}
                            </p>
                        </Pagination>
                    </Footer>
                )}
            </Content>
        </Layout>
    );
};

export default PostsTemplate;

export const pageQuery = graphql`
    query postsPageQuery($skip: Int!, $limit: Int!) {
        localSearchPages {
            index
            store
        }
        allGoogleDocs(
            sort: { fields: date, order: DESC }
            limit: $limit
            skip: $skip
        ) {
            edges {
                node {
                    slug
                    date(formatString: "YYYY, MMM DD")
                    name
                    cover {
                        image {
                            childImageSharp {
                                gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH, formats: [AUTO, AVIF, WEBP])
                            }
                        }
                    }
                    summary
                }
            }
        }
    }
`;
