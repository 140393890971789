import React, { useState, useRef, useCallback } from 'react';
import debounce from 'lodash/debounce';
import styled from 'styled-components';
import { forExtraSmallDevices } from '../utils/styles';

const Root = styled.div`
    width: 100%;
    margin-bottom: 1.5rem;

    ${forExtraSmallDevices(`
        margin-bottom: 5px;
    `)}
`;

const SearchContainer = styled.div`
    position: relative;
    width: 100%;
    max-width: 200px;
    height: 50px;
`;

const Toggle = styled.label`
    position: absolute;
    width: 100%;
    max-width: 200px;
    height: 100%;
    border-radius: 25px;
    background-color: ${({ theme }) => theme.colors.darkGray};
    transition: 0.2s;
    color: ${({ theme }) => theme.colors.white};
    font-weight: 900;
    cursor: pointer;
    // justify text
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;

    &::before {
        content: attr(data-title);
    }
`;

const Input = styled.input`
    position: absolute;
    border: 0;
    outline: 0;
    border-radius: 25px;
    opacity: 0;
    visibility: hidden;
    transform: scale(0.7);
    transition: 0s;
    color: black;
    height: 100%;
    width: 100%;
    padding: 0 25px;
    border: ${({ theme }) => `1px solid ${theme.colors.darkGray}`};

    &::placeholder {
        color: ${({ theme }) => theme.colors.darkGray};
    }
`;

const HiddenCheckbox = styled.input`
    display: none;
    
    &:not(:checked) + ${SearchContainer} {
        ${Input} {
            display: hidden;
            opacity: 0;
            transform: scale(0.7);
        }
    }

    &:checked + ${SearchContainer} {
        max-width: 300px;

        ${Toggle} {
            visibility: hidden;
            opacity: 0;
            transform: scale(0.7);
        }

        ${Input} {
            transition: 0.2s 0.1s;
            visibility: visible;
            opacity: 1;
            transform: scale(1);
        }
    }
`;

export const SearchBar = ({ searchQuery, setSearchQuery }) => {
    const inputRef = useRef();
    const [isFocused, setIsFocused] = useState(false);
    const handleCheckChange = () => {
        window?.gtag?.(
            'event',
            isFocused ? 'stop_search' : 'start_search'
        );
        setIsFocused(!isFocused);
        setTimeout(() => inputRef.current.focus(), 500);
    };
    const handleTextBlur = () => {
        if (!searchQuery) {
            window?.gtag?.('event', 'stop_search');
            setIsFocused(false);
        }
    };
    const sendSearchAnalytics = useCallback(
        debounce(
            searchQuery => {
                window?.gtag?.('event', 'search', {
                    search_term: searchQuery
                });
            },
            500
        ),
        []
    );
    const handleSearchChange = event => {
        const searchQuery = event.target.value;
        sendSearchAnalytics(searchQuery);
        setSearchQuery(searchQuery);
    };

    return (
        <Root>
            <HiddenCheckbox
                type='checkbox'
                id='checkbox'
                onChange={handleCheckChange}
                checked={isFocused}
            />
            <SearchContainer>
                <Input
                    value={searchQuery}
                    onInput={handleSearchChange}
                    placeholder='Search recipes'
                    type='text'
                    onBlur={handleTextBlur}
                    ref={inputRef}
                />
                <Toggle
                    htmlFor='checkbox'
                    data-title='Search recipes'
                />
            </SearchContainer>
        </Root>
    );
};
