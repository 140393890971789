import styled from 'styled-components';
import {
    forSmallDevices,
    forExtraSmallDevices
} from '../utils/styles';

export const Content = styled.div`
    padding: 20px 20px 20px 260px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;

    &::before,
    &::after {
        content: "";
        display: table;
    }

    &::after {
        clear: both;  
    }

    ${forSmallDevices(`
        padding: 20px 10px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: stretch;
    `)}

    ${forExtraSmallDevices(`
        padding: 10px;
    `)}
`;
